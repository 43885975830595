/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Subtitle, Text, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--10 pl--25 pr--25 pt--10" name={"prazdna_sekcee"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1 --full mt--10 pb--10" columns={"1"} fullscreen={true}>
          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"qq4iyeyagfi"} layout={"l2"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/i/moduls/email-flat-1.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":60}} RootClassName={"column__icon"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Kontakt"}>
              </Subtitle>

              <Text className="text-box fs--22" content={"+420 724 952 477\n<br>+420 606 025 400\n<br>podlahychalupa@gmail.com\n<br>Id datové schránky: kft6mn9"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/i/moduls/phone-flat-1.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":60}} RootClassName={"column__icon"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Provozní doba"}>
              </Subtitle>

              <Text className="text-box fs--22" content={"Konzultace a poptávky:\n<br>&nbsp;PO - PÁ 8.00 - 17.00 hod.\n<br>Zaměření a realizace:\nDle dohody"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/i/moduls/adress-flat-1.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":60}} RootClassName={"column__icon"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Vladimír Chalupa"}>
              </Subtitle>

              <Text className="text-box fs--22" content={"Dolnozahradská 1769/17\n<br>767 01 Kroměříž<br>Česká Republika\n<br>IČO: 21151920"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style3" action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

              <Text className="text-box" content={"<span style=\"color: rgb(9, 9, 38);\">podlahy Kroměříž, podlahy Kromeriz, podlahářské práce Kroměříž, podlaharske prace Kromeriz, podlahář Kroměříž, podlahar Kromeriz, podlaháři Kroměříž, podlahari Kromeriz, prodej podlah Kroměříž, podlahové studio Kroměříž, podlahy Chalupa Kroměříž, podlahy Chalupa Kromeriz</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}